export default class HomeService {
    constructor() {
        if ($('.home').length > 0) {
            if (!IRISCollectionCustomer.isEcomodeActive) {
                import(
                    '@scripts/components/front-page/slider-home.component' /* webpackChunkName: "scripts/slider-home.component" */
                ).then(({ default: SliderHomeComponent }) => {
                    new SliderHomeComponent()
                })

                if (window.matchMedia('(min-width: 1001px)').matches) {
                    import(
                        '@scripts/components/front-page/strate-activities.component.js' /* webpackChunkName: "scripts/strate-activities.component" */
                    ).then(({ default: StrateActivitiesComponent }) => {
                        new StrateActivitiesComponent()
                    })

                    import(
                        '@scripts/components/front-page/strate-sejour.component.js' /* webpackChunkName: "scripts/strate-sejour.component" */
                    ).then(({ default: StrateSejourComponent }) => {
                        new StrateSejourComponent()
                    })
                }

                import(
                    '@scripts/components/front-page/strate-event.component.js' /* webpackChunkName: "scripts/strate-event.component" */
                ).then(({ default: StrateEventComponent }) => {
                    new StrateEventComponent()
                })

                import(
                    '@scripts/components/front-page/strate-map.component.js' /* webpackChunkName: "scripts/strate-map.component" */
                ).then(({ default: StrateMapComponent }) => {
                    new StrateMapComponent()
                })

                import(
                    '@scripts/services/animation.service' /* webpackChunkName: "scripts/animation.service" */
                ).then(({ default: AnimationService }) => {
                    new AnimationService()
                })

                if (window.matchMedia('(min-width: 999px)').matches) {
                    import(
                        '@scripts/components/front-page/strate-socials.component' /* webpackChunkName: "scripts/strate-socials.service" */
                    ).then(({ default: StrateSocialsComponent }) => {
                        new StrateSocialsComponent()
                    })
                }
            }
        }
    }
}
